require('@rails/ujs').start()

// 初期化処理
import Initializer from 'lib/initializer'

Initializer.loadWebfont();
Initializer.lazy();
Initializer.navigation();
Initializer.smoothScroll();
Initializer.analytics();
//Initializer.turbolinksDebug();

// turbolinksでのリクエスト時に発動
document.addEventListener('turbolinks:load', () => {

  Initializer.lazy();
  Initializer.smoothScroll();

  // instagram埋め込みturbolink対策
  if (window.instgrm) window.instgrm.Embeds.process();
});
